@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none !important;
}

.z-4 {
  z-index: 4;
}

.z-3 {
  z-index: 3;
}

#outerContainer #mainContainer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#outerContainer #mainContainer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}

body[tabindex=1] {
  overflow: hidden;
}

body[tabIndex=1] {
  overflow: hidden;
}

body[tabindex="1"] {
  overflow: hidden;
}

body[tabIndex="1"] {
  overflow: hidden;
}