#about {
    margin: 2em;
    text-align: justify;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#about>h1 {
    margin-bottom: 1em;
}

#about a:hover {
    text-decoration: none;
}

#about ul {
    display: flex;
    justify-content: space-evenly;
}

#about ul>*:nth-child(even) {
    color: #6a6c6e;
}

#about .hint-span {
    color: #00660b;
    cursor: pointer;
}

#footer {
    position: fixed;
    bottom: 0;
    min-height: 160px;
    max-height: 400px;
    width: 100%;
    background-color: black;
    color: silver !important;
    text-align: center;
}

#footer h1,
#footer h2 {
    margin: 0.5rem 0;
}

#footer ul {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 2.5rem;
    font-size: 2.5rem;
}

#footer ul i {
    color: silver;
}

#secret {
    color: inherit;
    text-decoration: none;
}