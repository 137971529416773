.carte {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 420px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  overflow: hidden;
}

.carte-image {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carte-image img {
  max-width: 100%;
  max-height: 100%;
}

.carte-contenu {
  padding: 10px;
}

.carte-titre {
  font-size: 20px;
  margin: 0;
}

.carte-description {
  font-size: 14px;
  margin: 10px 0;
}

.carte-caracteristiques {
  list-style: none;
  padding: 0;
  margin: 0;
}

.carte-caracteristiques li {
  font-size: 12px;
  margin: 5px 0;
}

.star {
  width: 20px;
}

.health-bar {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100px;
  height: 20px;
  padding: 5px;
  background: #ddd;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  margin: 0.3em 0;
}
.bar {
  background: #c54;
  height: 10px;
  position: relative;
  
  transition: width .5s linear;
}